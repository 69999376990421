import React from "react";
import { connector, ContainerProps } from "./containers/Like.container";

export type LikeProps = {
  isLiked: boolean;
  isAuthenticated: boolean;
  onClick?: Function;
  onMount?: Function;
};

export const LikeWrapper: React.FC<LikeProps> = ({
  isLiked,
  isAuthenticated,
  onClick = () => false,
  onMount = () => false,
}) => {
  return (
      <div>
        <div
          onClick={() => onClick(isAuthenticated)}
          className="cursor-pointer flex justify-center items-center text-gray-400 hover:text-red-500 transition ease-in-out duration-300 rounded-full h-12 w-12 transform hover:scale-125"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className={`h-6 w-6 ${isLiked && "fill-current text-red-500"}`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </div>
      </div>
  );
};

export const LikeContainer: React.FC<ContainerProps> = (props) => (
  <LikeWrapper {...props} />
);

export const Like = connector(LikeContainer);
