import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../redux/actions"
import { RootState } from "../../redux/store"

const mapState = (state: RootState) => ({
  poem: state.poems.selectedPoem,
  haveNext: state.poems.haveNext,
  havePrevious: state.poems.havePrevious,
  isFetching: state.poems.fetching,
})

const mapDispatch = (dispatch: any) => ({
  onMount: () => {
    dispatch(actions.poems.fetchPoems())
    dispatch(actions.auth.fetchIsAuthenticated()).then(() => {
      dispatch(actions.likes.fetchLikes())
      dispatch(actions.profile.fetchUserPoems())
    })
  },
  onNext: () => dispatch(actions.poems.next()),
  onPrevious: () => dispatch(actions.poems.previous()),
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
