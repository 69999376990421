import React, { useEffect } from "react";

const Key: React.FC<{ className: string; onClick?: Function }> = ({
  className,
  children,
  onClick = () => {},
}) => (
  <div
    className={`transition-colors duration-300 ease-in-out rounded flex justify-center items-center h-6 w-6 bg-gray-200 text-gray-400 mb-1 cursor-pointer hover:bg-gray-300 hover:text-gray-500 ${className}`}
    onClick={() => onClick()}
  >
    <svg
      stroke="currentColor"
      strokeWidth="2"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      viewBox="0 0 24 24"
      className="h-4 w-4"
    >
      {children}
    </svg>
  </div>
);

const FakeEvent = () => {};

export const Keyboard: React.FC<{
  onLeft?: Function;
  onRight?: Function;
  onUp?: Function;
  onDown?: Function;
}> = ({
  onLeft = FakeEvent,
  onRight = FakeEvent,
  onDown = FakeEvent,
  onUp = FakeEvent,
}) => {
  useEffect(() => {
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === "ArrowLeft") return onLeft();
      if (event.key === "ArrowUp") return onUp();
      if (event.key === "ArrowDown") return onDown();
      if (event.key === "ArrowRight") return onRight();
    }

    window.addEventListener("keydown", onKeyDown);

    return () => {
      window.removeEventListener("keydown", onKeyDown);
    };
  });

  return (
    <div className="fixed bottom-0 right-0 p-4">
      <Key className="mb-1 ml-auto mr-auto">
        <path d="M12 19V5M5 12l7-7 7 7"></path>
      </Key>
      <div className="flex">
        <Key className="mr-1 ml-auto" onClick={onLeft}>
          <path d="M19 12H5M12 19l-7-7 7-7"></path>
        </Key>
        <Key className="">
          <path d="M12 5v14M19 12l-7 7-7-7"></path>
        </Key>
        <Key className="ml-1" onClick={onRight}>
          <path d="M5 12h14M12 5l7 7-7 7"></path>
        </Key>
      </div>
    </div>
  );
};
