import { navigate } from "gatsby"
import { connect, ConnectedProps } from "react-redux"
import { actions } from "../../../redux/actions"
import { RootState } from "../../../redux/store"

const mapState = (state: RootState, props: { id?: string }) => {
  const isAuthenticated = state.auth.isConnected
  const isLiked = state.likes.likes.find(({ id }) => id === props.id)

  return {
    isAuthenticated,
    isLiked: Boolean(isLiked),
  }
}

const mapDispatch = (dispatch: any) => ({
  onMount: () => {},
  onClick: (isAuthenticated: boolean) => {
    if (isAuthenticated) return dispatch(actions.likes.fetchToggle())
    navigate("/se-connecter/", { state: { from: "/", action: "like" } })
  },
})

export const connector = connect(mapState, mapDispatch)
export type ContainerProps = ConnectedProps<typeof connector>
