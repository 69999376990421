import { Link } from "gatsby"
import React from "react"
import { PoemEntity } from "../../entities/PoemEntity"

export type PoemProps = {
  poem: PoemEntity
  full?: boolean
  hideLinkAuthor?: boolean
}

export const Poem: React.FC<PoemProps> = ({
  poem,
  full = true,
  hideLinkAuthor = false,
}) => (
  <nav
    className={`w-full ${
      full && "min-h-screen max-h-screen"
    } flex items-center`}
  >
    <div className="container">
      <div className="flex justify-center">
        <div>
          <div
            className="relative font-serif text-xl italic text-center text-gray-700 md:text-2xl"
            dangerouslySetInnerHTML={{ __html: poem.html.replaceAll("<br>", "") }}
          ></div>

          <p className="relative my-8 font-serif text-base italic text-center text-gray-500">
            {hideLinkAuthor && (
              <span className="cursor-pointer hover:underline hover:text-green-600">
                {poem.user.firstName} {poem.user.lastName}
              </span>
            )}
            {!hideLinkAuthor && (
              <div>
                <Link
                  to={`/auteurs/${poem.user.id}/`}
                  className="capitalize cursor-pointer hover:underline hover:text-green-600"
                >
                  {poem.user.firstName} {poem.user.lastName}
                </Link>
              </div>
            )}
          </p>

          {poem.category && (
            <div className="flex justify-center w-full">
              <div className="inline px-2 py-1 text-sm font-medium text-gray-700 bg-gray-100 rounded-md">
                {poem.category.label}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </nav>
)
