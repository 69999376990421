import React, { useEffect } from "react"
import { Arrows } from "../components/Arrows/Arrows"
import { Poem } from "../components/Poem/Poem"
import { Keyboard } from "../components/Keyboard/Keyboard"
import { PoemEntity } from "../entities/PoemEntity"
import { connector, ContainerProps } from "./containers/Poems.container"
import { Like } from "../components/Like/Like"
import { Drawer } from "../components/Drawer/Drawer"
import { Navigation } from "../components/Marketing"

type PoemsProps = {
  poem: PoemEntity | null
  haveNext: boolean
  havePrevious: boolean
  isFetching: boolean
  onNext?: Function
  onMount?: Function
  onPrevious?: Function
}

const Arrow: React.FC<{
  direction: "left" | "right"
}> = ({ direction }) => {
  if (direction === "left")
    return (
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    )

  return (
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M9 5l7 7-7 7"
    />
  )
}

const Button: React.FC<{
  direction: "left" | "right"
  onClick: Function
  disabled?: boolean
}> = ({ direction, children, onClick, disabled = false }) => {
  const enabledStyle =
    "text-gray-500 shadow-xl hover:shadow-none hover:text-gray-800 hover:bg-gray-100"
  const disabledStyle = "text-gray-300 cursor-not-allowed"

  return (
    <button
      onClick={() => onClick()}
      disabled={disabled}
      className={`group relative focus:outline-none focus:ring-2 ring-gray-800 p-6 ${direction === "left" ? "text-right" : "text-left"} sm:text-center uppercase font-bold text-sm rounded-xl transition duration-300 ease-in-out ${
        disabled ? disabledStyle : enabledStyle
      }`}
    >
      <span className={`${direction === "left" ? "pl-8" : "pr-8"}`}>
        {children}
      </span>
      <div
        className={`${direction === "left" ? "left-0" : "right-0"} ${
          !disabled ? "group-hover:text-gray-800" : ""
        } absolute top-0 bottom-0 h-full flex items-center px-4 text-gray-300 transition duration-300 ease-in-out`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="h-6 w-6"
        >
          <Arrow direction={direction} />
        </svg>
      </div>
    </button>
  )
}

const PoemsWrapper: React.FC<PoemsProps> = ({
  poem,
  haveNext,
  havePrevious,
  isFetching,
  onNext = () => false,
  onPrevious = () => false,
  onMount = () => false,
}) => {
  useEffect(() => {
    onMount()
  }, [])

  return (
    <>
      <div className="bg-gray-50 min-h-screen">
        <Navigation />

        {/* <div className="hidden sm:block">
          <Arrows
            hasLeft={havePrevious}
            hasRight={haveNext}
            onLeft={() => onPrevious()}
            onRight={() => onNext()}
          />
        </div> */}

        {poem && <Poem poem={poem} />}

        {!isFetching && poem && (
          <div className="flex justify-center fixed bottom-0 left-0 right-0 m-auto mb-24">
            <Like id={poem.id} />
          </div>
        )}

        <div className="fixed bottom-0 left-0 right-0 w-full grid grid-cols-2 p-4 gap-4">
          <Button
            disabled={!havePrevious}
            onClick={() => onPrevious()}
            direction="left"
          >
            Précédent
          </Button>
          <Button
            disabled={!haveNext}
            onClick={() => onNext()}
            direction="right"
          >
            Suivant
          </Button>
        </div>

        <div className="hidden">
          <Keyboard onLeft={() => onPrevious()} onRight={() => onNext()} />
        </div>
      </div>

      <Drawer />
    </>
  )
}

export const PoemsContainer: React.FC<ContainerProps> = props => (
  <PoemsWrapper {...props} />
)

export default connector(PoemsContainer)
