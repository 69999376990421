import React from "react"
import { PageProps } from "gatsby"
import Poems from "../routes/Poems"
import { Seo } from "../components/Seo/Seo"

export default ({ location }: PageProps) => (
  <>
    <Seo
      index={false}
      title="Les haïkus de la communauté en français | Temple du Haïku"
      description="La communauté des haïkistes publient régulièrement des haïkus sur cette page. Il y a pleins d'exemple de haïkus pour vous inspirer."
    />
    <Poems />
  </>
)
